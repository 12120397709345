import React, { useEffect, useState } from 'react'
import { Button, Card, FileInput, Label, Modal, Tabs } from 'flowbite-react'

import axios from 'axios';
import { toast } from 'react-toastify';


function Reports() {
    const [data, setData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [updatedData, setUpdatedData] = useState({});
    let activity;
    const fetchData = async () => {
        const ipwhitelist = await axios.post('https://mmec.zxcommunity.in/users', {
            method: 'POST'
        }).then((response) => {
            setData(response.data);

        }).catch((err) => {
            console.log(err);
        })
    }
    useEffect(() => {
        fetchData();
    }, [])



    const [file, setFile] = useState(null);

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        setFile(selectedFile);
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        if (!file) {
            // File not selected, handle the error
            return;
        }

        const formData = new FormData();
        formData.append('csvfile', file);

        fetch('https://mmec.zxcommunity.in/upload', {
            method: 'POST',
            body: formData,
        })

            .then((response) => {

                // Handle the server response
                if (response.ok) {
                    toast.success('Successfully Uploaded Attendance', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    setFile(null);
                    document.getElementById('file').value = "";
                }
            })
            .catch((error) => {
                // Handle the error
                console.error(error);
            });
    };

    const User = (item) => {
        setShowModal(!showModal);
        const { userid, badgenumber, name, active } = item
        if (active === 1) {
            activity = 'Active'
        }
        else {
            activity = 'Not Active'
        }

        const data = {
            Userid: userid,
            BadgeNumber: badgenumber,
            Name: name,
            Active: activity
        }
        setModalData(data)
    }

    const closeModal = () => {
        setShowModal(!showModal)

    }
    
    const handleSaveCSV = async(data)=>{
        const updatedUserData = {
            ...updatedData,
            userid: data.Userid,
            name: data.Name
        }


        const userReport = await axios.post('https://mmec.zxcommunity.in/userReportCSV', updatedUserData, {
            headers: {
                'Content-Type': 'application/json'
            },
            responseType: 'blob' // Set the response type to 'blob'
        }).then((response) => {
            const url = window.URL.createObjectURL(response.data); // Use response.data instead of response.blob()
            const a = document.createElement('a');
            a.href = url;
            a.download = `Report-of-${updatedUserData.name}.csv`;
            document.body.appendChild(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url);
            setShowModal(!showModal);
            toast.success(`Report Downloaded for ${updatedUserData.name}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });

        }).catch((error) => {
            toast.error(`Report was not generated for ${updatedUserData.name}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            setShowModal(!showModal);
        });
    }

    const handleSave = async (data) => {

        const updatedUserData = {
            ...updatedData,
            userid: data.Userid,
            name: data.Name,
            badgenumber:data.BadgeNumber
        }
        

        const userReport = await axios.post('https://mmec.zxcommunity.in/userReport', updatedUserData, {
            headers: {
                'Content-Type': 'application/json'
            },
            responseType: 'blob' // Set the response type to 'blob'
        }).then((response) => {
            const url = window.URL.createObjectURL(response.data); // Use response.data instead of response.blob()
            const a = document.createElement('a');
            a.href = url;
            a.download = `Report-of-${updatedUserData.name}.pdf`;
            document.body.appendChild(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url);
            setShowModal(!showModal);
            toast.success(`Report Downloaded for ${updatedUserData.name}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });

        }).catch((error) => {
            toast.error(`Report was not generated for ${updatedUserData.name}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            setShowModal(!showModal);
        });
    }

    return (
        <>


            
            <Tabs.Group  aria-label="Default tabs"
                style="default">
                <Tabs.Item title="Import Attendance">
                    <Card className=''>
                        <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                            Import The Checktime
                        </h5>
                        <form onSubmit={handleSubmit}>
                            <div className="flex items-center mt-6 ">
                                <FileInput
                                    helperText="Please upload the file format in *.csv"
                                    id="file"
                                    onChange={handleFileChange}
                                />
                                <Button type="submit" className="ml-4">
                                    Submit
                                </Button>
                            </div>
                        </form>
                    </Card>
                </Tabs.Item>
                <Tabs.Item title="Users">
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-6">
                        {data.map((item) => (
                            <div key={item.userid}>
                                <Card>
                                    <div className="flex flex-col items-center pb-10">
                                        <img
                                            alt="Bonnie image"
                                            className="mb-3 rounded-full shadow-lg"
                                            height="96"
                                            src="https://img.icons8.com/?size=1x&id=23264&format=png"
                                            width="96"
                                        />
                                        <h5 className="mb-1 text-xl font-medium text-gray-900 dark:text-white">
                                            {item.name}
                                        </h5>
                                        <span className="text-sm text-gray-500 dark:text-gray-400">
                                            User ID: {item.userid}
                                        </span>
                                        <span className="text-sm text-gray-500 dark:text-gray-400">
                                            Badge Number: {item.badgenumber}
                                        </span>
                                        <div className="mt-4 space-x-3">
                                            <Button
                                                className="bg-cyan-700 hover:bg-cyan-800 text-white"
                                                onClick={() => User(item)}
                                            >
                                                Generate Report
                                            </Button>
                                            <Button
                                                className="border border-gray-300 bg-white text-gray-900 hover:bg-gray-100"
                                                href="#"
                                            >
                                                Message
                                            </Button>
                                        </div>
                                    </div>
                                </Card>
                            </div>
                        ))}
                    </div>
                </Tabs.Item>
                </Tabs.Group>
            

            {modalData && (
                <Modal popup size="md" show={showModal} onClose={closeModal}>
                    <Modal.Header >
                        
                        </Modal.Header>
                    <Modal.Body>
                        <div className="space-y-6 px-6 pb-4 sm:pb-6 lg:px-8 xl:pb-8">
                            <h3 className="text-xl font-medium text-gray-900 dark:text-white">
                                Generate Report for {modalData.Name}
                            </h3>
                            <div>
                                <div className="mb-2 block">
                                    <Label htmlFor="fromDate" value="From Date" />
                                </div>
                                <input
                                    type="date"
                                    className="rounded-lg"
                                    id="fromDate"
                                    onChange={(e) =>
                                        setUpdatedData({ ...updatedData, fromDate: e.target.value })
                                    }
                                />
                            </div>
                            <div>
                                <div className="mb-2 block">
                                    <Label htmlFor="toDate" value="To Date" />
                                </div>
                                <input
                                    type="date"
                                    className="rounded-lg"
                                    id="toDate"
                                    onChange={(e) =>
                                        setUpdatedData({ ...updatedData, toDate: e.target.value })
                                    }
                                />
                            </div>
                            <Button  onClick={() => handleSave(modalData)}>
                                Generate Report
                            </Button>
                            <Button  onClick={() => handleSaveCSV(modalData)}>
                                Generate Report CSV
                            </Button>
                        </div>
                    </Modal.Body>
                </Modal>
            )}
        </>
    )
}

export default Reports