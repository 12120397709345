import React, { useState, useEffect,useRef } from 'react'
import { Modal, Label, Table, TextInput, Button } from 'flowbite-react'

import axios from 'axios';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserGroup, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-regular-svg-icons';

function Users() {
    let activity;
    const [data, setData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [updatedData, setUpdatedData] = useState({});
    const [userShowModal, setUserShowModal] = useState(false);
    const [addUserData, setAddUserData] = useState({});
    const [userIdExists,setUserIdExists] = useState(false);

    const fetchData = async () => {
        const ipwhitelist = await axios.post('https://mmec.zxcommunity.in/users', {
            method: 'POST'
        }).then((response) => {
            setData(response.data);

        }).catch((err) => {
            console.log(err);
        })
    }
    useEffect(() => {

        fetchData();
    }, []);

    



    const [file, setFile] = useState(null);

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!file) {
            // File not selected, handle the error
            return;
        }

        const formData = new FormData();
        formData.append('csvfile', file);

        fetch('https://mmec.zxcommunity.in/uploadUser', {
            method: 'POST',
            body: formData,
        })

            .then((response) => {

                // Handle the server response
                if (response.ok) {
                    toast.success('Successfully Uploaded Users', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    setFile(null);
                    document.getElementById('file').value = "";
                    fetchData();
                }
            })
            .catch((error) => {
                // Handle the error
                console.error(error);
            });

    };

    const User = async (item) => {
        const { userid, badgenumber, name, active } = item



        if (active === 1) {
            activity = 'Active'
        }
        else {
            activity = 'Not Active'
        }

        const data = {
            Userid: userid,
            BadgeNumber: badgenumber,
            Name: name,
            Active: activity
        }
        setModalData(data);

        setShowModal(!showModal);


    }



    const closeModal = () => {
        setShowModal(!showModal);
        setModalData(null);
    }
    const handleSave = async () => {

        const updatedUserData = {
            ...modalData,
            Userid: document.getElementById("userid").value,
            Name: document.getElementById("name").value,
            BadgeNumber: document.getElementById("badgenumber").value,
            Active: document.getElementById("active").value === "Active" ? 1 : 0,
        };

        console.log(updatedUserData)
        const userUpdate = await axios.post('https://mmec.zxcommunity.in/usersUpdate', updatedUserData, {
                headers: {
                    'Content-Type': 'application/json'
                }
        });

        if (userUpdate.data.success) {
            toast.success('Successfully Edited!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            setModalData(null);
            setShowModal(!showModal);
            fetchData();
            // window.location.reload();
        }
        else {
            toast.error('Something went wrong!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            setShowModal(!showModal);
            window.location.reload();
        }




    }
    
    const addUser = () => {
        setUserShowModal(!userShowModal);
    }
    const userCloseModal = () => {
        setUserShowModal(!userShowModal);
    }

    const addHandleSave = async() => {
        const UserData = {
            ...addUserData,
            // Userid: document.getElementById("userid").value,
            // Name: document.getElementById("name").value,
            // BadgeNumber: document.getElementById("badgenumber").value,
             Active: document.getElementById("addActive").value === "Active" ? 1 : 0,
        };
        console.log(UserData)
        // const userAdd = await axios.post('https://mmec.zxcommunity.in/userAdd', UserData, {
        const userAdd = await axios.post('https://mmec.zxcommunity.in/userAdd', UserData, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        if(userAdd.data.success)
        {
            toast.success(`Successfully Added ${UserData.Name} `, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            setUserShowModal(!userShowModal);
            fetchData();
        }
        else
        {
            toast.error(`${UserData.Name} Not Added `, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            setUserShowModal(!userShowModal);
        }
    }

    return (
        <>
            <div className="container mx-auto">
            <div className="rounded-lg shadow-lg">
                <h1 className="text-2xl font-bold mb-4 p-3 text-center"><FontAwesomeIcon icon={faUserGroup}/> Import Users</h1>
                <form onSubmit={handleSubmit} className="flex flex-col items-center mb-8">
                    <label htmlFor="file" className="text-lg mb-2">
                    Select CSV file:
                    </label>
                    <div className="flex items-center">
                    <input
                        type="file"
                        id="file"
                        accept=".csv"
                        onChange={handleFileChange}
                        className="p-2 border border-gray-300 rounded-lg mb-2 mr-2"
                    />
                    <Button type="submit" className="bg-blue-500 hover:bg-blue-600 text-white rounded-lg mb-2">
                        Submit
                    </Button>
                    </div>
                </form>
                </div>
                <Table className="w-full">
                    <Table.Head>
                        <Table.HeadCell>User ID</Table.HeadCell>
                        <Table.HeadCell>Badge Number</Table.HeadCell>
                        <Table.HeadCell>Name</Table.HeadCell>
                        <Table.HeadCell>Active</Table.HeadCell>
                        <Table.HeadCell>Action</Table.HeadCell>
                        <Table.HeadCell onClick={addUser} className='bg-green-600 text-gray-50 text-center cursor-pointer hover:animate-pulse'><FontAwesomeIcon className='text-lg' icon={faUserPlus} /> Add User</Table.HeadCell>
                    </Table.Head>
                    <Table.Body className="divide-y">
                        {data.map((item) => (
                            <Table.Row className="bg-white dark:bg-gray-800">
                                <Table.Cell>{item.userid}</Table.Cell>
                                <Table.Cell>{item.badgenumber}</Table.Cell>
                                <Table.Cell>{item.name}</Table.Cell>
                                <Table.Cell>{item.active === 1 ? 'Active' : 'Not Active'}</Table.Cell>
                                <Table.Cell>
                                    <Button className="text-cyan-600 dark:text-cyan-500" onClick={() => User(item)}>
                                        Edit
                                    </Button>
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </div>

            {/* EDIT USER MODAL */}
            <Modal
                popup
                size="md"
                show={showModal}
                onClose={closeModal}
            >
                <Modal.Header />
                <Modal.Body>
                    {modalData && (
                        <div className="space-y-6 px-6 pb-4 sm:pb-6 lg:px-8 xl:pb-8">
                            <h3 className="text-xl font-medium text-gray-900 dark:text-white">
                                {modalData.Name}
                            </h3>

                            <div>
                                <div className="mb-2 block">
                                    <Label
                                        htmlFor="userid"
                                        value="User ID"
                                    />
                                </div>
                                <TextInput
                                    id="userid"
                                    required
                                    value={modalData.Userid}
                                    readOnly
                                />
                            </div>

                            <div>
                                <div className="mb-2 block">
                                    <Label
                                        htmlFor="name"
                                        value="Name"
                                    />
                                </div>
                                <TextInput
                                    id="name"
                                    placeholder="Faizudheen"
                                    required
                                    defaultValue={modalData.Name}
                                    onBlur={(e) => setUpdatedData({ ...updatedData, Name: e.target.value })}
                                />
                            </div>

                            <div>
                                <div className="mb-2 block">
                                    <Label
                                        htmlFor="badgenumber"
                                        value="Badge Number"
                                    />
                                </div>
                                <TextInput
                                    id="badgenumber"
                                    placeholder="Faizudheen"
                                    required
                                    defaultValue={modalData.BadgeNumber}
                                    onBlur={(e) => setUpdatedData({ ...updatedData, BadgeNumber: e.target.value })}
                                />
                            </div>
                            <div>
                                <select onChange={(e) => setUpdatedData({ ...updatedData, Active: e.target.value })} id="active">
                                    <option>Active</option>
                                    <option>Not Active</option>
                                </select>
                            </div>
                            <Button className='text-center bg-green-400' onClick={handleSave}>Save</Button>
                        </div>

                    )}

                </Modal.Body>
            </Modal>


            {/* ADD USER MODAL */}
            <Modal
                popup
                size="md"
                show={userShowModal}
                onClose={userCloseModal}
                
            >
                <Modal.Header />
                <Modal.Body>
                    <div className="space-y-6 px-6 pb-4 sm:pb-6 lg:px-8 xl:pb-8">
                        <h3 className="text-xl font-medium text-gray-900 dark:text-white">
                            Add User
                        </h3>

                        <div>
                            <div className="mb-2 block">
                                <Label htmlFor="userid" value="User ID" />
                            </div>
                            <TextInput
                                id="addUserid"
                                required
                                className={userIdExists ? "border-red" : ""}
                                onBlur={(e) => {
                                    setAddUserData({ ...addUserData, Userid: e.target.value })
                                        for(let i=0;i<data.length;i++)
                                        {
                                            if(e.target.value ==  data[i].userid)
                                            {
                                                setUserIdExists(!userIdExists);
                                                toast.error('User ID already exists!', {
                                                    position: toast.POSITION.BOTTOM_LEFT,
                                                  });
                                                  
                                                
                                                  break;
                                            }
                                            
                                        }
                                }}
                               
                                
                            />
                           
                        </div>

                        <div>
                            <div className="mb-2 block">
                                <Label htmlFor="name" value="Name" />
                            </div>
                            <TextInput
                                id="addName"
                                placeholder="Faizudheen"
                                required
                                onBlur={(e) => setAddUserData({ ...addUserData, Name: e.target.value })}
                            />
                        </div>

                        <div>
                            <div className="mb-2 block">
                                <Label htmlFor="badgenumber" value="Badge Number" />
                            </div>
                            <TextInput
                                id="addBadgenumber"
                                placeholder="112"
                                required
                                onBlur={(e) => setAddUserData({ ...addUserData, BadgeNumber: e.target.value })}
                            />
                        </div>

                        <div>
                            <select
                                onChange={(e) => setAddUserData({ ...addUserData, Active: e.target.value })}
                                id="addActive"
                            >
                                <option>Active</option>
                                <option>Not Active</option>
                            </select>
                        </div>

                        <Button className="text-center bg-green-400" onClick={addHandleSave}>
                            Save
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>

        </>
    )
}

export default Users