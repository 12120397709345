import { Navbar } from 'flowbite-react'
import React from 'react'
import { useNavigate } from 'react-router-dom'


let MyNavBar = () => {
    const navigate = useNavigate();
    return (
        <>
          

                
                <Navbar
                    fluid 
                   
                >
                    <Navbar.Brand className='cursor-pointer'>
                        <img
                            alt="Flowbite Logo"
                            className="mr-3 h-6 sm:h-9"
                            src="https://netamart.com/wp-content/uploads/2020/04/LOGO_NETAMART.png"
                        />
                        <span className="self-center whitespace-nowrap text-xl font-semibold ">
                            Attendance Report
                        </span>
                    </Navbar.Brand>
                    <Navbar.Toggle />
                    <Navbar.Collapse >
                        <Navbar.Link
                            onClick={()=>navigate('/')}
                            className='cursor-pointer text-xl  '

                        >
                            Home
                        </Navbar.Link>
                        <Navbar.Link onClick={()=>navigate('/users')} className='cursor-pointer text-xl'>
                            Users
                        </Navbar.Link>
                        <Navbar.Link onClick={()=>navigate('/report')} className='cursor-pointer text-xl'>
                            Reports
                        </Navbar.Link>
                    
                    </Navbar.Collapse>
                </Navbar>
         


        </>
    )
}
export default MyNavBar