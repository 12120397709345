import React from "react";
import MyNavBar from "./Components/MyNavBar/MyNavBar";
import Cards from "./Components/Cards/Cards";
import Users from "./Pages/Users/Users";
import {BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Reports from "./Pages/Reports/Reports";


function App() {
  return (
    <div>
      <Router>
      <MyNavBar />
        <Routes>
          
          <Route path='/users' element={<Users />} />
          <Route path='/report' element={<Reports />} />
        </Routes>
        <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="colored"
            />
      </Router>
    </div>
  );
}

export default App;
